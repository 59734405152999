<template>
  <div class="about container">
    <header>
      <h1 data-aos="fade-up" data-aos-duration="1000">
       
        <br />Apasionada en <b>solucionar problemas</b> y crear productos
        digitales que
        <b>ayudan a las personas.</b>
        ✨ 
        <br>〰
      </h1>

      <div class="imgMain" data-aos="fade-up" data-aos-duration="1000">
        <img
          src="img/portrait.png"
          alt="Es un retrato fotográfico en escala de grises de Patricia Borrallo"
        />
      </div>

      <div class="img_bg">
        <div class="fila" data-aos="fade-left" data-aos-duration="2000">
          <div class="imagen">
            <img src="img/nature.jpeg" alt="Una libélula en la naturaleza" />
          </div>
          <div class="imagen" data-aos="fade-up" data-aos-duration="2000">
            <img
              src="img/design.jpg"
              alt="Una chica trabajando con notas adhesivas"
            />
          </div>
        </div>
        <div class="fila" data-aos="fade-left" data-aos-duration="3000">
          <div class="imagen">
            <img src="img/carbon.jpeg" alt="Un conejo gris" />
          </div>
          <div class="imagen" data-aos="fade-up" data-aos-duration="3000">
            <img src="img/flower.jpg" alt="Flor margarita" />
          </div>
        </div>
      </div>
    </header>

    <section
      class="sectionText bio"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div class="titlesection">
        <h2>Sobre mí —</h2>
      </div>

      <div class="text">
        <p class="big">
          Hola 👋🏻!! Soy Patricia, diseñadora de producto digital, siempre he estado
          interesada por el diseño y la tecnología, desde hace 3 años me dedico
          profesionalmente al diseño de producto.
        </p>
        <p>
          Comencé estudiando diseño gráfico pero mis ganas de aprender me
          enfocaron en el terreno digital estudiando el Máster de <b>diseño
          interactivo.💡</b>
        </p>
        <p>
          Durante mi carrera profesional he trabajado con diferentes empresas
          ayudando a <b>mejorar productos digitales, aportar soluciones nuevas o
          crear nuevos productos</b>. Aunque me interesan todos los procesos de
          diseño de un producto desde la concepción de la idea e investigación
          hasta el wireframing, la creación de prototipos y la validación, mi
          enfoque principal es trabajar en la mejor <b>✨ experiencia de usuario.✨
          </b>
        </p>
        <p>
          Además del trabajo, en mi tiempo libre me apasiona dibujar, la
          fotografía, aprender cosas nuevas, viajar y ver películas y series de
          ciencia ficción.🚀
        </p>

        <div class="Clients">
          <h3>Equipos y Proyectos</h3>
          <p>
            Orbitfy Studio, Bisiesto, Holafly, Z1 Studio, Magma, Sedee,
            PaintUber, Flyncer, DM app, Throw, Mailman, Nestegg
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Header from "../components/Header.vue";
export default {
  name: "About",
  components: { Header },
};
</script>


<style lang="scss">
.about {
  margin: 0 auto;

  header {
    display: flex;
  height: 95vh;
    min-height: 600px;
    align-items: center;
    justify-content: center;

    h1 {
      width: 44rem;
      position: relative;
      z-index: 2;
      padding: 10rem 0 0 0;
    mix-blend-mode: multiply; 

    }
    .imgMain {
      width: 22rem;
      position: absolute;
      z-index: 1;
      left: 0;
      right:0;
      margin: auto; 
      background-color: white;

    img{
      opacity: 0.7;
    }
    }
  }
  .img_bg {
    height: auto;
    display: flex;
    position: relative;
    opacity: 0.3;


    .imagen {
      margin: 0 0 0.75rem 0.75rem;
      max-width: 18rem;
      min-width: 11.5rem;
    }
  }
  .big {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.5rem;
  }
  .bio{
    margin: 10rem 0;
  }
}
</style>